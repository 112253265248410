import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconBell from 'components/icons/iconBell';
import IconMail from 'components/icons/iconMail';
import { Link } from 'react-router-dom';

const ContactBtns = ({ variant = '' }) => (
  <div
    className={classNames(
      { 'my-8': variant === 'dsr' },
      { 'my-0 md:mx-4 xl:mx-12 md:max-w-none': variant !== 'dsr' },
      'flex max-w-[400px] mx-auto'
    )}
  >
    <div
      className={classNames(
        'text-sm flex flex-1 justify-center px-0 py-2 font-bold text-gray text-center',
        'min-[1440px]:max-w-[80%] min-[1440px]:mx-auto min-[1440px]:my-0',
        '[&_svg_path]:stroke-[#999]',
        'first:[border-bottom:none]',
        '[&:nth-child(3)]:[border-top:none]',
        '[&_a]:text-[inherit] [&_a]:no-underline',
        '[&_img]:w-full [&_img]:h-full',
        '[&_span]:w-[34px] [&_span]:h-[34px] [&_span]:flex [&_span]:justify-center [&_span]:items-center [&_span]:mt-0 [&_span]:mx-auto [&_span]:mb-2.5'
      )}
    >
      <a href="https://help.joybird.com">
        <span>
          <IconBell />
        </span>
        FAQ
      </a>
    </div>

    <div
      className={classNames(
        'text-sm flex flex-1 justify-center px-0 py-2 font-bold text-gray text-center',
        'min-[1440px]:max-w-[80%] min-[1440px]:mx-auto min-[1440px]:my-0',
        '[&_svg_path]:stroke-[#999]',
        'first:[border-bottom:none]',
        '[&:nth-child(3)]:[border-top:none]',
        '[&_a]:text-[inherit] [&_a]:no-underline',
        '[&_img]:w-full [&_img]:h-full',
        '[&_span]:w-[34px] [&_span]:h-[34px] [&_span]:flex [&_span]:justify-center [&_span]:items-center [&_span]:mt-0 [&_span]:mx-auto [&_span]:mb-2.5'
      )}
    >
      <Link to="/contact-us/">
        <span>
          <IconMail />
        </span>
        Contact Us
      </Link>
    </div>
  </div>
);

ContactBtns.propTypes = {
  variant: PropTypes.string,
};

export default ContactBtns;
